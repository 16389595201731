import React from 'react';
import { unstable_TooltipBase as TooltipBase } from '@design-system'

/* @ts-ignore */
interface TooltipProps extends
  React.ComponentProps<typeof TooltipBase.Content>,
  Pick<React.ComponentProps<typeof TooltipBase>, 'defaultOpen' | 'open' | 'onOpenChange'> {
  content: React.ReactNode | string | number
  disabled?: boolean
}

export function unstable_Tooltip({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  disabled,
  ...props
}: TooltipProps) {
  return (
    <TooltipBase
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
    >
      {disabled ? (
        children
      ) : (
        <TooltipBase.Trigger asChild>
          <span>{children}</span>
        </TooltipBase.Trigger>
      )}
      <TooltipBase.Content side="top" align="center" {...props}>
        {content}
        <TooltipBase.Arrow width={11} height={5} />
      </TooltipBase.Content>
    </TooltipBase>
  );
}
