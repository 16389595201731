
import React from "react"
import { type DialogProps } from "@radix-ui/react-dialog"
import { Command as CommandPrimitive } from "cmdk"

import { Icon, unstable_Dialog as Dialog, cn } from "@design-system"

const Root = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
  <CommandPrimitive
    ref={ref}
    className={cn(
      "flex h-full w-full min-w-[220px] flex-col overflow-hidden rounded-lg bg-white text-primary dark:bg-gray-100 border border-gray-30 dark:border-gray-90",
      className
    )}
    {...props}
  />
))
Root.displayName = CommandPrimitive.displayName

const CommandDialog = ({ children, ...props }: DialogProps) => {
  return (
    <Dialog {...props}>
      <Dialog.Content className="overflow-hidden p-0 shadow-lg">
        <Root className="">
          {children}
        </Root>
      </Dialog.Content>
    </Dialog>
  )
}

const Input = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({ className, ...props }, ref) => (
  <div className="flex items-center px-3 border-b border-gray-30 dark:border-gray-60" cmdk-input-wrapper="">
    <Icon name="search" size="sm" />
    <CommandPrimitive.Input
      ref={ref}
      className={cn(
        "flex h-11 w-full bg-transparent rounded-md border-0 py-3 text-sm outline-none placeholder:text-gray-80 dark:placeholder:text-gray-40 disabled:cursor-not-allowed disabled:opacity-50 focus-within:ring-0",
        className
      )}
      {...props}
    />
  </div>
))

Input.displayName = CommandPrimitive.Input.displayName

const List = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cn("max-h-[300px] py-2 overflow-y-auto overflow-x-hidden", className)}
    {...props}
  />
))

List.displayName = CommandPrimitive.List.displayName

const Empty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
  <CommandPrimitive.Empty
    ref={ref}
    className="py-6 text-center text-sm text-secondary"
    {...props}
  />
))

Empty.displayName = CommandPrimitive.Empty.displayName

const Group = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Group
    ref={ref}
    className={cn(
      "overflow-hidden first:border-t-0 first:mt-0 first:pt-0 mt-2 pt-2 border-t border-gray-30 dark:border-gray-70 [&_[cmdk-group-heading]]:px-4 [&_[cmdk-group-heading]]:py-2 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-bold [&_[cmdk-group-heading]]:text-gray-80 dark:[&_[cmdk-group-heading]]:text-gray-40",
      className
    )}
    {...props}
  />
))

Group.displayName = CommandPrimitive.Group.displayName

const Separator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={cn("-mx-1 h-px my-2 bg-gray-30 dark:bg-gray-60", className)}
    {...props}
  />
))
Separator.displayName = CommandPrimitive.Separator.displayName

const Item = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cn(
      "relative flex gap-2 cursor-pointer select-none items-center rounded-sm px-4 py-2 text-sm outline-none aria-selected:bg-alpha/5 data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50",
      className
    )}
    {...props}
  />
))

Item.displayName = CommandPrimitive.Item.displayName

const Shortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={cn(
        "ml-auto text-xs tracking-widest text-gray-70 dark:text-gray-50",
        className
      )}
      {...props}
    />
  )
}
Shortcut.displayName = "CommandShortcut"

/**
 * Command is a command menu React component that can also be used as an accessible combobox. 
 * You render items, it filters and sorts them automatically. Command supports a fully composable API How?, so you can wrap items in other components or even as static JSX.
 * 
 * [API Reference](https://github.com/pacocoursey/cmdk)
 */
export const Command = Object.assign(Root, {
  Root,
  Dialog: CommandDialog,
  Input,
  List,
  Empty,
  Group,
  Item,
  Shortcut,
  Separator,
})
