import React from 'react'
import { Toaster as Sonner } from 'sonner'

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      position="bottom-center"
      style={{
        fontFamily: "inherit",
      }}
      toastOptions={{
        unstyled: true,
        className: "py-2 px-6 flex gap-2 items-center text-white rounded shadow-lg data-[removed=true]:hidden", // data-[removed=true]:hidden is a hack to hide the toast when it's removed, otherwise it would only be hidden with opacity-0 and it would avoid elements under it being able to be clicked.
        classNames: {
          success: "bg-green-50",
          error: "bg-danger-50",
          toast: "bg-gray-100",
          title: "!font-bold whitespace-nowrap",
          description: "!font-bold",
        },
      }}
      {...props}
    />
  )
}

export { Toaster }