import React, { useState, ReactNode } from 'react'
import { unstable_Dialog as Dialog, Typography, Button } from '@design-system'

interface ConfirmModalProps {
  /**
   * Control the modal open state
   */
  isOpen: boolean
  /**
   * Callback fired when the component requests to be closed.
   */
  onCancel: any
  /**
   * Callback fired when the confirm button is fired.
   */
  onConfirm: any
  /**
   * Modal Title
   */
  title: string
  /**
   * Modal subtitle
   */
  subtitle?: string
  /**
   * Text label for button with confirm action
   */
  confirmLabel?: string
  /**
   * Text label for button with cancel action
   */
  cancelLabel?: string
  /**
   * Children element placed between description and buttons
   */
  children?: ReactNode | undefined
}

/**
 * The ConfirmModal provides a easy-to-use Headless Ui Modal to confirm a action
 */
const ConfirmModal = ({
  isOpen,
  onCancel,
  onConfirm,
  confirmLabel,
  cancelLabel,
  title,
  subtitle,
  children,
}: ConfirmModalProps) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const handleConfirm = async () => {
    setSubmitting(true)
    await onConfirm()
    setSubmitting(false)
  }
  return (
    <Dialog open={isOpen} onOpenChange={onCancel}>
      <Dialog.Content hideCloseButton className="max-w-sm">
        <Dialog.Header className="mb-4">
          <Typography variant="heading-6" weight="bold" data-testid="title">
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="body" color="secondary" data-testid="subtitle">
              {subtitle}
            </Typography>
          )}
        </Dialog.Header>
        {children}
        <div className="flex flex-row-reverse justify-start gap-2">
          <Button loading={isSubmitting} onClick={handleConfirm} data-testid="confirm">
            {confirmLabel}
          </Button>
          <Button disabled={isSubmitting} onClick={onCancel} variant="outlined" data-testid="cancel">
            {cancelLabel}
          </Button>
        </div>
      </Dialog.Content>
    </Dialog>
  )
}

ConfirmModal.defaultProps = {
  isOpen: false,
  confirmLabel: 'Confirm',
  cancelLabel: 'Cancel',
  title: 'Are you sure?',
  isSubmitting: false,
}

ConfirmModal.displayName = 'ConfirmModal'

export { ConfirmModal }

export type { ConfirmModalProps }
