import React, { Children, useRef, createContext, useContext, PropsWithChildren } from 'react'
import { Button, unstable_Popover as Popover, Command, Icon, Typography, cn, Spinner } from "@design-system"

const getOptions = (children: React.ReactNode) => {
  const options = Children.toArray(children)
    .map((child: React.ReactElement) => {
      if (typeof child === 'object' && child.type && (child.type as React.ComponentType<any>).displayName === 'Combobox.Item') {
        return { value: child.props.value, children: child.props.children }
      } else if (typeof child === 'object' && child.type && (child.type as React.ComponentType<any>).displayName === 'Combobox.Group') {
        return getOptions(child.props.children)
      }
    })
  return options.flat()
}

interface ComboboxContextType {
  multiple: boolean
  selectedValue: string | string[]
  handleChange: (value: any) => void
}

const ComboboxContext = createContext<ComboboxContextType | undefined>(undefined);

export const useComboboxContext = () => {
  const context = useContext(ComboboxContext)
  if (!context) {
    throw new Error('useComboboxContext must be used within a ComboboxProvider')
  }
  return context
}

interface RootProps extends PropsWithChildren, React.ComponentPropsWithoutRef<'select'> {
  label?: string
  name: string
  multiple?: boolean
  placeholder?: string
  emptyText?: string
  search?: React.ReactNode
  defaultValue?: string | string[]
  helperText?: string
  error?: string | boolean
  loading?: boolean
  popoverAlign?: 'start' | 'center' | 'end'
}

export function Root({ label, name, multiple, placeholder, emptyText, search, children, defaultValue, className, required, disabled, helperText, error, loading, popoverAlign, ...rest }: RootProps) {
  const options = getOptions(children)
  const [open, setOpen] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState(multiple ? defaultValue ?? [] : defaultValue ?? '')
  const [selectedChildren, setSelectChildren] = React.useState(multiple ? undefined : options.find(option => option.value === defaultValue)?.children)
  const selectRef = useRef(null)

  const handleOpen = (value) => {
    if (loading) return
    setOpen(value)
  }

  const handleChange = ({ value, children }) => {
    const dispatchChangeEvent = () => {
      const event = new Event('change', { bubbles: true })
      selectRef.current.dispatchEvent(event)
    }

    if (multiple) {
      setSelectedValue(prev => {
        const isValueSelected = prev.includes(value)
        /* @ts-ignore */
        const newValue = isValueSelected ? prev.filter(v => v !== value) : [...prev, value]
        const options = selectRef.current.options;
        for (let i = 0; i < options.length; i++) {
          options[i].selected = newValue.includes(options[i].value)
        }
        dispatchChangeEvent()
        return newValue
      })
    } else {
      setSelectedValue(value)
      setSelectChildren(children)
      setOpen(false)
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, 'value').set
      nativeInputValueSetter.call(selectRef.current, value)
      dispatchChangeEvent()
    }
  }

  const placeholderChildren = <Typography color="secondary">{placeholder}</Typography>

  return (
    <div className={cn("space-y-1", className)}>
      {label && (
        <Typography asChild variant="callout">
          <label htmlFor={name}>
            {label}
            {required && (!helperText || (error && typeof error !== 'string')) && <Required />}
          </label>
        </Typography>
      )}
      <ComboboxContext.Provider value={{ multiple, selectedValue, handleChange }}>
        <Popover open={open} onOpenChange={handleOpen}>
          <Popover.Trigger asChild>
            <Button
              variant="input"
              role="combobox"
              aria-expanded={open}
              className={cn("w-full flex justify-between gap-2", Boolean(error) && "!border-danger")}
              size="lg"
            >
              {multiple && 
                <>
                  {placeholderChildren}
                  {selectedValue.length > 0 && (
                    <span className="rounded-full shrink-0 bg-gray-100 dark:bg-gray-2 text-white dark:text-gray-100 text-xs font-bold py-0.5 px-2 ml-auto">{selectedValue.length}</span>
                  )}
                </>
              }
              {!multiple && <section className='truncate'>{selectedChildren ? selectedChildren : placeholderChildren}</section>}
              {loading ? <Spinner size="sm" /> : <Icon name="chevron-down" className="shrink-0" />}
            </Button>
          </Popover.Trigger>
          <Popover.Content className="p-0 max-h-[var(--radix-popover-trigger-height)] w-full min-w-[var(--radix-popover-trigger-width)]" align={popoverAlign || 'end'} sideOffset={0}>
            <Command>
              {search}
              <Command.List>
                <Command.Empty>{emptyText ?? "No results"}</Command.Empty>
                {children}
              </Command.List>
            </Command>
          </Popover.Content>
        </Popover>
        <select
          {...rest}
          ref={selectRef}
          name={name}
          multiple={multiple}
          className="visually-hidden"
          aria-hidden="true"
          tabIndex={-1}
          defaultValue={selectedValue}
          required={required}
          disabled={disabled}
        >
          <option value=""></option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.value}
            </option>
          ))}
        </select>
      </ComboboxContext.Provider>
      <div>
        {typeof error === 'string' && (
          <Typography variant="footnote" color="danger" className="flex items-center gap-1">
            <Icon size="2xs" name="alert-triangle-filled" />
            <span>
              {error}
              {required && <Required />}
            </span>
          </Typography>
        )}
        {typeof error !== 'string' && helperText && (
          <Typography variant="footnote" color="tertiary">
            {helperText}
            {required && <Required />}
          </Typography>
        )}
      </div>
    </div>
  )
}
Root.displayName = 'Combobox'

const Required = () => (
  <span className="text-danger font-bold">
    {' *'}
  </span>
)

const Group = React.forwardRef<
  React.ElementRef<typeof Command.Group>,
  React.ComponentPropsWithoutRef<typeof Command.Group>
>(({ ...props }, ref) => (
  <Command.Group
    ref={ref}
    {...props}
  />
))
Group.displayName = 'Combobox.Group'

const Item = React.forwardRef<
  React.ElementRef<typeof Command.Item>,
  React.ComponentPropsWithoutRef<typeof Command.Item>
>(({ value, children, ...props }, ref) => {
  const { multiple, selectedValue, handleChange } = useComboboxContext()
  const isSelected = multiple ? selectedValue.includes(value) : selectedValue === value
  return (
    <Command.Item
      ref={ref} {...props} onSelect={() => handleChange({ value, children })}>
      {children}
      {isSelected && (
        <Icon name="check" size="xs" className="ml-auto" />
      )}
    </Command.Item>
  )
})
Item.displayName = 'Combobox.Item'

const Search = React.forwardRef<
  React.ElementRef<typeof Command.Input>,
  React.ComponentPropsWithoutRef<typeof Command.Input>
>(({ ...props }, ref) => (
  <Command.Input
    ref={ref}
    {...props}
  />
))
Search.displayName = 'Combobox.Search'

/**
 * Autocomplete input and command palette with a list of suggestions.
 */
export const Combobox = Object.assign(Root, { Item, Search, Group })
