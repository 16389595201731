import React, { PropsWithChildren } from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { cn, Typography } from '@design-system'

const rootVariants = cva(
  "flex flex-col items-center gap-y-1 text-center",
  {
    variants: {
      direction: {
        row: "lg:grid gap-x-4 grid-cols-[auto_1fr] grid-rows-[1fr_auto_auto_auto_1fr] lg:text-left",
        column: "group is-column",
      },
    },
    defaultVariants: {
      direction: "row",
    },
  }
)

interface RootProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof rootVariants> { }

const Root = ({ children, className, direction, ...props }: RootProps) => {
  return (
    <div className="flex justify-center bg-white dark:bg-gray-100 rounded-lg py-6 lg:py-10 px-4 lg:px-20">
      <div className={cn(rootVariants({ className, direction }))} {...props}>
        {children}
      </div>
    </div>
  )
}
Root.displayName = "EmptyState"

const Illustration = ({ children }: PropsWithChildren) => (
  <>
    <div className="mb-4 group-[.is-column]:mb-4 lg:my-0 row-span-full dark:*:invert">
      {children}
    </div>
    <div />
  </>
)
Illustration.displayName = "EmptyState.Illustration"

const Title = ({ children }: PropsWithChildren) => (
  <Typography variant="body" weight="bold">
    {children}
  </Typography>
)
Title.displayName = "EmptyState.Title"

const Description = ({ children }: PropsWithChildren) => (
  <Typography variant="callout" className="lg:w-80">
    {children}
  </Typography>
)
Description.displayName = "EmptyState.Description"

const Actions = ({ children }: PropsWithChildren) => (
  <nav className="mt-4 flex flex-wrap justify-center lg:justify-start gap-4">
    {children}
  </nav>
)
Actions.displayName = "EmptyState.Actions"

/**
 * EmptyState is used to indicate that there is no data to display.
 */
export const EmptyState = Object.assign(Root, { Illustration, Title, Description, Actions })
