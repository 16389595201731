import * as React from "react"
import * as AccordionPrimitive from "@radix-ui/react-accordion"

import { cn } from '../../lib/cn'
import { Icon } from "../../atoms/icon"

const Root = AccordionPrimitive.Root

const Item = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn("border border-gray-30 rounded-lg bg-white dark:border-gray-90 dark:bg-gray-100", className)}
    {...props}
  />
))
Item.displayName = "AccordionItem"

const Trigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="p-4 flex hover:bg-gray-5 dark:hover:bg-gray-98">
    <AccordionPrimitive.Trigger
      ref={ref}
      className="flex gap-2 flex-1 items-center text-left justify-between font-medium transition-all [&[data-state=open]>#chevron-icon]:rotate-180"
      {...props}
    >
      <div className={cn("grow", className)}>{children}</div>
      <Icon id="chevron-icon" name="chevron-down" size="md" className="shrink-0 transition-transform duration-200" />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
Trigger.displayName = AccordionPrimitive.Trigger.displayName

const Content = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="overflow-hidden p-4 pt-0 text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={cn("pb-4 pt-0", className)}>{children}</div>
  </AccordionPrimitive.Content>
))

Content.displayName = AccordionPrimitive.Content.displayName

export const unstable_Accordion = Object.assign(Root, {
  Item, Trigger, Content,
})
