import { useEffect } from 'react'
import useConfirmModal from '@hooks/useConfirmModal'
import { useFetcher as originalUseFetcher, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

export function useFetcher(params = undefined) {
  const fetcher = originalUseFetcher(params)
  const confirm = useConfirmModal()
  const navigate = useNavigate()

  useEffect(() => {
    if (fetcher.formMethod === 'post' && fetcher.state === 'loading') {

      if (fetcher.data?.toast) {
        toast[fetcher.data.toast.appearance](fetcher.data.toast.message)
      }

      if (fetcher.data?.confirmModal) {
        confirm(fetcher.data.confirmModal).then(confirmed => {
          if (confirmed) {
            const formData = fetcher.formData
            formData.set('_confirm', 'true')
            fetcher.submit(formData, {
              method: fetcher.formMethod,
              action: fetcher.formAction,
            })
          }
        })
      }

      if (fetcher.data?.redirect) {
        navigate(fetcher.data.redirect)
      }
    }
  }, [fetcher.data, fetcher.state, fetcher.formMethod])

  return fetcher
}
