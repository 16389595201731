import React, { ForwardedRef, forwardRef } from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { Slot } from "@radix-ui/react-slot"
import { Icon, cn } from '@design-system'

export interface CardProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof cardVariants> {
  /**
   * Use the asChild prop to compose [Radix's](https://www.radix-ui.com/primitives/docs/guides/composition) functionality onto alternative element types or your own React components.
   */
  asChild?: boolean
  /**
   * Avoid using classes that affect the typographic style.
   */
  className?: string
}

const cardVariants = cva(
  "flex items-center justify-between gap-4 border border-gray-30 dark:border-gray-90 bg-white dark:bg-gray-100 rounded-lg",
  {
    variants: {
      actionable: {
        false: "",
        true: "cursor-pointer hover:bg-gray-2 focus-within:bg-alpha-5 focus-within:ring-accent transition-colors"
      },
      active: {
        false: "",
        true: "!bg-gray-10"
      }
    },
    defaultVariants: {
      actionable: false,
      active: false
    },
  }
)

/**
 * Container that groups related content and actions.
 */
const Root = forwardRef<HTMLDivElement, CardProps>
  (({ className, actionable, active, asChild = false, ...props }: CardProps, ref: ForwardedRef<HTMLDivElement>) => {
    const Comp = asChild ? Slot : "article"
    return (
      <Comp
        className={cn(cardVariants({ className, actionable, active }))}
        ref={ref}
        {...props}
      />
    )
  })

Root.displayName = "Card"

const cardContentVariants = cva(
  "w-full",
  {
    variants: {
      padding: {
        xs: "p-0",
        sm: "p-4",
        md: "p-6",
      },
    },
    defaultVariants: {
      padding: "md",
    },
  }
)

export interface CardContentProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof cardContentVariants> {
  /**
   * Use the asChild prop to compose [Radix's](https://www.radix-ui.com/primitives/docs/guides/composition) functionality onto alternative element types or your own React components.
   */
  asChild?: boolean
  /**
   * Avoid using classes that affect the typographic style.
   */
  className?: string
}

const Content = forwardRef<HTMLDivElement, CardContentProps>
  (({ className, padding, asChild = false, ...props }: CardContentProps, ref: ForwardedRef<HTMLDivElement>) => {
    const Comp = asChild ? Slot : "div"
    return (
      <Comp
        className={cn(cardContentVariants({ className, padding }))}
        ref={ref}
        {...props}
      />
    )
  })
Content.displayName = "Card.Content"

const Arrow = () => (
  <Icon name="chevron-right" className="mx-4" />
)
Arrow.displayName = "Card.Arrow"

/**
 * Displays a card with header, content, and footer.
 */
export const Card = Object.assign(Root, { Content, Arrow })
