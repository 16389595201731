import React, { PropsWithChildren, useRef, useState, useEffect } from 'react'
import { SoraNavLink } from '@components/link'
import { Button, Icon, cn } from '@design-system'

interface RootProps extends React.HTMLAttributes<HTMLUListElement>, PropsWithChildren { }

function Root({ children, className, ...props }: RootProps) {

  const tabListRef = useRef<HTMLUListElement>(null)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(false)

  const checkScrollable = () => {
    if (tabListRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabListRef.current
      setCanScrollLeft(scrollLeft > 0)
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 1)
    }
  }

  useEffect(() => {
    checkScrollable()
    window.addEventListener('resize', checkScrollable)
    if (tabListRef.current) {
      tabListRef.current.addEventListener('scroll', checkScrollable)
    }
    return () => {
      window.removeEventListener('resize', checkScrollable)
      if (tabListRef.current) {
        tabListRef.current.removeEventListener('scroll', checkScrollable)
      }
    }
  }, [])

  const scrollLeft = () => {
    if (tabListRef.current) {
      tabListRef.current.scrollBy({ left: -250, behavior: 'smooth' })
    }
  }

  const scrollRight = () => {
    if (tabListRef.current) {
      tabListRef.current.scrollBy({ left: 250, behavior: 'smooth' })
    }
  }

  return (
    <div className={cn("sticky top-20 z-10 mb-4 border-b dark:border-gray-90 bg-screen-primary flex items-center gap-2", className)}>
      {canScrollLeft && (
        <div className="flex items-center absolute z-10 top-0 bottom-0 -left-2 pl-2 bg-gradient-to-r from-gray-2 via-gray-2 dark:from-gray-100 dark:via-gray-100 w-16">
          <Button onClick={scrollLeft} variant="ghost" size="xs">
            <Icon name="chevron-left" size="xs" />
          </Button>
        </div>
      )}
      <ul ref={tabListRef} className="-ml-2 grow flex gap-4 overflow-x-auto no-scrollbar" role="tablist" {...props}>
        {children}
      </ul>
      {canScrollRight && (
        <div className="flex items-center justify-end absolute z-10 top-0 bottom-0 right-0 bg-gradient-to-l from-gray-2 via-gray-2 dark:from-gray-100 dark:via-gray-100 w-16">
          <Button onClick={scrollRight} variant="ghost" size="xs">
            <Icon name="chevron-right" size="xs" />
          </Button>
        </div>
      )}
    </div >
  )
}

interface TabsItemProps {
  title: string
  to: string
  disabled?: boolean
}

/**
 * A tab that represents a section of content.
 */
function Item({ title, to, disabled }: TabsItemProps) {
  const tabRef = useRef<HTMLLIElement>(null)

  const handleClick = () => {
    if (tabRef.current) {
      tabRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
    }
  }

  return (
    <li ref={tabRef} className="hover:bg-alpha/5 active:bg-alpha/10 rounded-t-md relative">
      <span className="font-bold invisible block py-4 mx-2 border-b-accent aria-[current=page]:border-b-3 aria-[current=page]:font-bold focus-within:ring-accent">{title}</span>
      <SoraNavLink onClick={handleClick} role="tab" to={to} replace className={cn("absolute text-center inset-0 block py-4 mx-2 border-b-accent aria-[current=page]:border-b-3 aria-[current=page]:font-bold focus-within:ring-accent", disabled && "pointer-events-none text-gray-70 dark:text-gray-70")}>
        {title}
      </SoraNavLink>
    </li>
  )
}

/**
 * A set of layered sections of content—known as tab panels—that are displayed one at a time.
 * 
 * This Tab component works best with Outlet from react-router-dom.
 */
export const Tabs = Object.assign(Root, { Item })
